<template>

    <div class="page-content">
        <div class="container-fluid">

            <Breadcrumb
                :pgTitle="$t('nav.settings')"
                :pgIcon="'bx bx-wrench'"
                :refs="refs"
                :addNew="addNew">
            </Breadcrumb>

            <Cards
                :refs="refs">
            </Cards>

        </div>
    </div>

</template>

<script>
export default {
    name: 'List',
    components: {
        Breadcrumb: () => import('@/components/Breadcrumb.vue'),
        Cards: () => import('@/components/Cards.vue'),
    },
    data(){
        return {    
            //
            addNew: false,
            cards: [],
            refs: 'settings',
        }
    },
    watch: {
        //
    },
    mounted() {},
    created() {
        //
    },
    methods: {
        handlePermissoinChange(event) {
            console.log(event)
            //this.addNew = event;
        },
    },
}
</script>
